'use client';
import { PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';
import { ThemeProvider } from '@reshima/theme';
import { ModalProvider } from '@reshima/modals-ui';
import { ClientAuthProvider } from '@reshima/client-auth-ui';
import { Dictionary } from '@reshima/translations';
import { TranslationsProvider } from '@reshima/translations-ui';
import { NavBar, SyncStatusProvider } from '@reshima/nav-bar-ui';
import { Footer } from './components/footer';

const Initializer = dynamic(async () => {
  await (await import('./initializations/initializer')).default();
  return ({ children }: PropsWithChildren) => children;
});

export function App({
  children,
  dictionary,
}: PropsWithChildren<{ dictionary: Dictionary }>) {
  return (
    <Initializer>
      <TranslationsProvider dictionary={dictionary}>
        <SyncStatusProvider>
          <ClientAuthProvider>
            <ThemeProvider>
              <ModalProvider>
                <NavBar />
                <main className="w-full flex-1 flex justify-center pt-1 pb-3 px-1 xs:px-2">
                  {children}
                </main>
                <Footer />
              </ModalProvider>
            </ThemeProvider>
          </ClientAuthProvider>
        </SyncStatusProvider>
      </TranslationsProvider>
    </Initializer>
  );
}
